<template>
  <CompanyHeader title="רשימת רכישות">
    <template v-slot:content>
      <v-container :class="credits.length ? '' : 'no-credits'">
        <div v-if="credits.length">
          <v-card
            v-for="credit in credits"
            :key="credit._id"
            flat
            wrap
            class="ma-5 rounded-xxl"
          >
            <v-row
              align="center"
              justify="space-between"
              class="card veryLightPurple"
            >
              <v-col cols="2">
                <v-avatar class="avatar">
                  <img class="pa-1" src="/person.png" lazy-src="/person.png" />
                </v-avatar>
              </v-col>
              <v-col>
                <div class="company-name">
                  {{ credit.user.firstName }}
                  {{ credit.user.lastName }}
                </div>
                <div class="credit-amount">
                  <span>
                    {{ credit.amount }}
                  </span>
                  ₪
                </div>
              </v-col>
              <v-col>
                <span>
                  <a :href="`tel:${credit.user.phone}`">
                    {{ credit.user.phone }}
                  </a>
                </span>
                <br />
                <span>
                  {{ credit.user.address }}
                </span>
                <br />
                <span>
                  {{ findPayType(credit.type) }}
                </span>
              </v-col>
              <v-col class="d-flex justify-center">
                <v-btn
                  class="gradient-btn pa-4"
                  x-small
                  v-text="'אישור'"
                  @click="paymentConfirmation(credit._id, credit.user._id)"
                />
              </v-col>
            </v-row>
          </v-card>
        </div>
        <h3 class="text-center" v-else>אין בקשות תשלום</h3>
        <QrCodeScannerHandler @result="openPayPage" />
      </v-container>
    </template>
  </CompanyHeader>
</template>

<script>
import QrCodeScannerHandler from "@/components/dialogs/QrCodeScannerHandler.vue";
import { successSwal, errorSwal } from "@/actions.js";
import { findPayType } from "@/handlers/payType";
import CompanyHeader from "@/components/company/CompanyHeader.vue";

export default {
  components: {
    CompanyHeader,
    QrCodeScannerHandler,
  },
  name: "PaymentRequests",
  methods: {
    findPayType,
    openMenu() {
      this.$store.commit("drawer/set", true);
    },
    openPayPage(url = "") {
      let isUrl = new URL(url);
      isUrl = Boolean(isUrl);
      if (isUrl) {
        const src = url.split("/company");
        this.$router.push(`/company${src[1]}`);
      }
    },
    async paymentConfirmation(creditId, userId) {
      try {
        await this.$store.dispatch("credit/update/company", {
          creditId,
          userId,
          status: "approved",
        });

        successSwal({ title: "הבקשה אושרה" });
        this.showPayRow = false;
      } catch (e) {
        errorSwal({ title: "אירעה שגיאה" });
      }
    },
  },
  computed: {
    credits() {
      return this.$store.getters.credits.filter((c) => c.status == "padding");
    },
  },
  async beforeMount() {
    await this.$store.dispatch("credit/index/company", { status: ["padding"] });
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 9px !important;
}

.home > .header {
  height: 160px;
}
.no-credits {
  padding: 30% 20%;
  justify-content: center;
}

a:link {
  text-decoration: none;
}
</style>
