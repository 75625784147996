import { render, staticRenderFns } from "./PaymentRequests.vue?vue&type=template&id=6a4cf826&scoped=true"
import script from "./PaymentRequests.vue?vue&type=script&lang=js"
export * from "./PaymentRequests.vue?vue&type=script&lang=js"
import style0 from "./PaymentRequests.vue?vue&type=style&index=0&id=6a4cf826&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a4cf826",
  null
  
)

export default component.exports