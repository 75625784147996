export const payType = [
  { text: "פייבוקס", value: "Paybox" },
  { text: "אשראי", value: "Credit" },
  { text: "העברה בנקאית", value: "Bank transfer" },
  { text: "ביט", value: "Bit" },
  { text: "מזומן", value: "Cash" },
];
export const findPayType = (value) => {
  const result = payType.find((type) => type.value === value);
  if (!result) throw new Error("Invalid type value: " + value);
  return result.text;
};
