<template>
  <div>
    <v-footer app style="background: center" class="center-all">
      <v-row class="d-flex justify-center mb-3" max-width="500px">
        <v-col class="center-all">
          <v-btn fab dark color="primary" @click="openScannerModal = true">
            <v-icon dark>mdi-qrcode</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
    <my-dialog
      v-if="openScannerModal"
      v-model="openScannerModal"
      title=""
      :max-width="500"
    >
      <template v-slot:content>
        <v-row>
          <v-col class="text-center">
            <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded" />
            <v-progress-circular
              v-if="isLoad"
              :size="150"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <h3>או הכנס קוד</h3>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="7">
            <v-text-field
              v-model="code"
              class="ps-10"
              hide-details
              label="קוד"
              outlined
              @keyup.enter="fromCode"
              @focus="$event.target.select()"
            >
              <template slot="append-outer" class="text-center">
                <v-icon color="primary" @click="fromCode" :disabled="!code">
                  mdi-arrow-left-bold
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog.vue";
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  name: "QrCodeScannerHandler",
  components: { MyDialog, StreamBarcodeReader },
  props: {
    value: Boolean,
  },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      isLoad: true,
      openScannerModal: false,
      code: "",
    };
  },
  methods: {
    onDecode(text) {
      this.$emit("result", text);
    },
    fromCode() {
      this.$router.push({ path: `/company/payment/${this.code}` });
    },
    onLoaded() {
      this.isLoad = false;
      console.log("load");
    },
  },
};
</script>
<style scoped lang="scss">
:deep(.laser) {
  margin: 0 20%;
}
</style>
